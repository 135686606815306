.location-popup {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  z-index: 1000;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
}

.location-popup.closed {
  transform: translateX(-100%);
}

.location-popup.open {
  transform: translateX(0);
}

.filter-space {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.filter-space.open {
  height: auto;
}

.toggle-button, .open-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 60px;
  background-color: white;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.toggle-icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.toggle-icon span {
  width: 3px;
  height: 100%;
  background-color: #333;
}

@media (max-width: 768px) {
  .toggle-icon {
    flex-direction: column;
  }

  .toggle-icon span {
    width: 100%;
    height: 3px;
  }
}

.toggle-button {
  right: -30px;
}

.open-button {
  left: 0;
}

.location-list {
  height: 1px;
  overflow-y: auto;
  flex-grow: 1;
  margin-top: 10px; /* Ensure space between filter bar and list */
}

@media (max-width: 768px) {
  .location-popup {
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 70%;
  }

  .location-popup.closed {
    transform: translateY(100%);
  }

  .location-popup.open {
    transform: translateY(0);
  }

  .toggle-button {
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 30px;
    border-radius: 4px 4px 0 0;
  }
  .sticky-filter-bar {
    padding: 5px; /* Reduce padding */
  }

  .filter-container {
    gap: 5px; /* Reduce gap between filters */
  }


}

.no-locations-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #666;
}
  .filter-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    overflow: hidden;
    transition: transform 0.1s ease;
  }

  .filter-button.shifted {
    transform: translateY(180px);
  }

  .filter-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .filter-button svg {
    fill: #333;
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  .ripple {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    width: 10px;
    height: 10px;
    animation: ripple 0.6s linear;
    pointer-events: none;
  }

  .sticky-filter-bar {
    background-color: white;
    z-index: 1000;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .location-list {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: 10px; /* Ensure space between filter bar and list */
  }

  .sticky-filter-bar.open {
    transform: translateY(0);
    opacity: 1;
  }

  .sticky-filter-bar.closed {
    transform: translateY(-100%);
    opacity: 0;
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
