  .layers-control {
    position: relative;
  }

  .layers-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .layers-button svg {
    width: 24px;
    height: 24px;
  }

  .layers-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1000;
  }

  .layer-option {
    padding: 8px 12px;
    cursor: pointer;
  }

  .layer-option:hover {
    background-color: #f0f0f0;
  }

.layers-icon {
  transition: transform 0.3s ease;
}

.layers-button:hover .layers-icon {
  transform: translateY(-3px);
}
