.custom-navbar {
    background-color: #FBB117 !important;
    border-bottom: 2px solid #9E4113;
    font-family: 'Segoe UI', sans-serif;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 10px 3% !important;
    width: 100%;
    min-width: fit-content;
}

/* Remove padding from navbar-collapse */
.navbar-collapse {
    padding: 0;
}

/* Add padding to the Nav component instead */
@media (max-width: 991.98px) {
    .custom-nav-links {
        padding: 15px 0;
    }
}

@media (min-width: 992px) {
    .custom-nav-links {
        padding: 0;
    }
}

/* Existing styles remain the same */
.navbar-brand {
    margin-left: 0;
}

.custom-nav-links {
    margin-right: 0;
}
  .custom-nav-links .nav-link {
    color: #9E4113 !important;
    font-weight: bold;
    font-size: 20px;
    transition: all 0.3s ease;
    position: relative;
    padding: 8px 32px; /* Doubled the horizontal padding */
    margin: 0 8px; /* Added margin between buttons */
  }
  
  .custom-nav-links .nav-link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #9E4113;
    transition: width 0.3s ease;
  }
  
  /* Hover effect to make text brighter */
  .custom-nav-links .nav-link:hover {
    color: #d2562c !important;
    transform: translateY(-2px);
    text-shadow: 2px 2px 4px rgba(158, 65, 19, 0.2);
  }
  
  .custom-nav-links .nav-link:hover::before {
    width: 100%;
  }
  