.custom-footer {
  background-color: #f8f9fa;
  color: #9E4113;
  padding: 2rem 0;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-section {
  flex: 1;
  padding: 0 1rem;
}

.footer-section h5 {
  margin-bottom: 1rem;
  font-weight: bold;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.footer-section ul li:hover {
  color: #bf6937;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(158, 65, 19, 0.1);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }
  
  .footer-section {
    margin-bottom: 1.5rem;
  }
}
