.pad{
    padding:  0 30px 0 0;
}
  .side-nav {
    width: 200px;
    height: 100%;
    transition: width 0.3s ease-in-out;
    position: relative;
    background-color: #f5f5f5;
  }

  .side-nav.closed {
    width: 0;
  }

  .nav-content {
    padding: 60px 20px 20px;
    background-color: #f5f5f5;
    width: 200px;
    height: 100%;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
  }

  .side-nav.closed .nav-content {
    transform: translateX(-250px);
  }

  .togglebutton {
    position: absolute;
    right: -44px;
    top: 0;
    background: #f5f5f5;
    border: 1px solid #9E4113;
    color: #9E4113;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 0 0 4px 0;
    z-index: 2;
  }

  .togglebutton:hover {
    background: #fff0e8;
  }
.nav-item {
  padding: 15px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #9E4113;
  color: #9E4113;
}

.nav-item.selected {
  background-color: #9E4113;
  color: white;
  transform: scale(1.02);
}

.nav-item:hover {
  background-color: #fff0e8;
}

.nav-item.selected:hover {
  background-color: #9E4113;
}

.nav-item.locked {
  opacity: 0.7;
  cursor: pointer;
}

.nav-item.locked:hover {
  background-color: #fff0e8;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .side-nav {

    position: absolute;
    left: 0;

    height: 100%;
    z-index: 100;
  }

  .nav-content {
    height: 100%;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }

  .pad {
    padding: 0;
    height: 100%;
  }
}
