.search-results {
  padding: 20px;
  position: relative;
  z-index: 1;
  min-width: fit-content;
}

@media screen and (max-width: 768px) {
  .results-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .results-grid {
    grid-template-columns: 1fr;
  }
}
