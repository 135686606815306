  .search-container {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: left 0.5s ease-in-out;
  }

  .search-box-shifted {
    left: 410px;
    /* transform: translateX(70%); */
  }

  .search-input-wrapper {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }

  .search-input-wrapper:focus-within {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }

  .search-input {
    flex-grow: 1;
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    outline: none;
  }
    .clear-button {
      background: white;
      border: none;
      cursor: pointer;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
    }

    .clear-icon {
      font-size: 18px;
      color: #888;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .search-input:not(:placeholder-shown) + .clear-button .clear-icon {
      opacity: 1;
    }

    .search-input-group {
      display: flex;
      }

      .search-button,
      .location-button,
      .layers-button {
        cursor: pointer;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        border: 0px solid #ccc;
        border-left: 1px solid #CCC;
        background: none;
        font-size: 1.2em;
        position: relative;
        overflow: hidden;
      }
      .clear-button {
        background: white;
      }

      .search-button svg,
      .location-button svg,
      .layers-button svg {
        width: 20px;
        height: 20px;
        color: black;
        transition: transform 0.3s ease;
      }

      .search-button:hover svg,
      .location-button:hover svg,
      .layers-button:hover svg {
        transform: translateY(-2px);
      }

 

    .location-button {
      height: 40px;
      padding: 0 15px;
      font-size: 14px;
   
      white-space: nowrap;
    }





.search-button.rate-limited {
  background-color: #cccccc;
  cursor: not-allowed;
}

.search-button.rate-limited:hover {
  background-color: #cccccc;
}


@media (max-width: 768px) {
  .search-container {
    transition: none;
    max-width: 418px;
    left: 50%;
    transform: translateX(-50%);
  }

  .search-box-shifted {
    left: 10px;
  }

  .search-input-group {
    flex-wrap: wrap;
  }

  .search-input {
    width: calc(100% - 40px); /* Leave space for clear button */
  }

  .search-button,
  .location-button,
  .layers-button {
    flex: 1;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 40px;
  }
}
  .layers-dropdown {
    transform: translateY(-10px);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out, max-height 0.3s ease-out;
  }

  .layers-dropdown.open {
    transform: translateY(0);
    opacity: 1;
    max-height: 200px;
    pointer-events: auto;
  }

  .layer-option {
    display: block;
    width: 100%;
    padding: 12px 18px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #333;
  }

  .layer-option:hover {
    background-color: #f5f5f5;
    color: #000;
  }
