.no-scroll {
  overflow: hidden;
}

.LocationServices {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */

/* Hide scrollbar for IE, Edge and Firefox */
