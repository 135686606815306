.alcohol-search-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.alcohol-search {
  display: flex;
  flex: 1;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.header-section {
  margin-bottom: 30px;
}

.header-section h1 {
  color: #9E4113;
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.header-section p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.4;
}
