.type-filters {
  margin: 25px 0;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid rgba(158, 65, 19, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-title {
  color: #9E4113;
  font-size: 1.1rem;
  margin-bottom: 20px;
  font-weight: 500;
}
  .filter-buttons {
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    width: 100%;
  }

  .type-button {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #9E4113;
    border-radius: 4px;
    background: white;
    color: #9E4113;
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: 0.8;
    font-size: 0.9rem;
    text-align: center;
    
  }
.type-button:hover {
  background-color: #fff0e8;
  transform: translateY(-1px);
}

.type-button.selected {
  background: #9E4113;
  color: white;
}

.premium-lock {
  text-align: center;
  color: #9E4113;
  margin-top: 15px;
  font-size: 0.9rem;
  opacity: 0.8;
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.lock-icon {
  font-size: 0.8rem;
}
