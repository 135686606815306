
  .interactive-map-container {
    position: relative;
    height: calc(100vh - 67px);
    width: 100vw;
  }

  .map-container {
    height: 100%;
    width: 100%;
  }

  .controls-container {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1000;
  }

  .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



  .error-message {
    color: red;
    margin-top: 10px;
  }

  @media (max-width: 768px) {
    .controls-container {
      top: auto;
      bottom: 10px;
      left: 10px;
      right: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
.brewery-type-filter select {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .search-container {
    width: calc(100% - 50px);
    
  }

  .controls-container {
    top: auto;
    bottom: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: space-between;
  }
}


@keyframes balloonEffect {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.balloon-marker {
  animation: balloonEffect 0.5s ease-out;
}
