.header {
  width: 100%;
  min-width: fit-content;
  padding: 20px;
  background-color: #fff;
  border-bottom: 2px solid #9E4113;
  text-align: center;
}

.header h1 {
  color: #9E4113;
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.header p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.4;
}
