.abv-slider {
  margin: 25px 0;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid rgba(158, 65, 19, 0.1);
}

.slider-title {
  color: #9E4113;
  font-size: 1.1rem;
  margin-bottom: 20px;
  font-weight: 500;
}

.slider-container {
  padding: 10px 8px;
  margin-bottom: 15px;
  cursor: pointer;
}
  .range-inputs {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 20px;
    padding: 0 10px;
    width:  50px;
  }

  .input-group {
    flex: 1;
    max-width: 45%;
  }

  .input-group label {
    display: block;
    color: #9E4113;
    font-size: 0.9rem;
    margin-bottom: 5px;
  }

  .range-inputs input {
    width: 80px;
    height: 36px;
    padding: 0;
    border: 1px solid #9E4113;
    border-radius: 4px;
    color: #9E4113;
    background: #fff;
    text-align: center;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.premium-lock {
  text-align: center;
  color: #9E4113;
  margin-top: 15px;
  font-size: 0.9rem;
  opacity: 0.8;
}

.rc-slider-track {
  background-color: #9E4113 !important;
}

.rc-slider-rail {
  background-color: #f5f5f5 !important;
}
