.alcohol-section {
    width: 100%;
    position: relative;
    background: #FBB117;
    border-bottom: 10px solid #9E4113;
}

.alcohol-header {
    width: 100%;
    height: 300px;
    position: relative;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease;
}

.alcohol-header:hover {
    transform: scale(1.01);
}

.alcohol-title-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(251, 177, 23, 0.9);
    padding: 1rem;
    height: 70px;
    backdrop-filter: blur(5px);
}

.alcohol-title {
    color: #9E4113;
    font-size: 2rem;
    margin-left: 2rem;
    font-weight: 600;
}

.expand-arrow {
    color: #9E4113;
    font-size: 2.5rem;
    font-weight: bold;
    margin-right: 2rem;
    transform: scaleX(1.5);
    transition: transform 0.3s ease;
}

.expand-arrow.active {
    transform: scaleX(1.5) rotateX(180deg);
}

.detail-section {
    background: white;
    border-radius: 12px;
    border: none;
    transition: transform 0.2s ease;
    padding: 1.5rem;
}

.detail-section:hover {
    transform: translateY(-5px);
}

.detail-section h3 {
    color: #9E4113;
    border-bottom: 2px solid #FBB117;
    padding-bottom: 0.8rem;
    margin-bottom: 1.2rem;
    font-weight: 600;
}
