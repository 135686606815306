.typing-text {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  width: 0;
}

.first-text {
  border-right: 2px solid #9E4113;
  animation: 
    typing 2s steps(40, end) forwards,
    blink-caret .75s step-end infinite 2s,
    remove-caret 0.1s forwards 2s;
}

.second-text {
  opacity: 0;
  border-right: 2px solid #9E4113;
  animation: 
    typing 2s steps(40, end) forwards 2s,
    blink-caret .75s step-end infinite 4s,
    remove-caret 0.1s forwards 4s;
}

@keyframes typing {
  from { 
    width: 0;
    opacity: 1;
  }
  to { 
    width: 100%;
    opacity: 1;
  }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #9E4113 }
}

@keyframes remove-caret {
  to {
    border-right: none;
  }
}
