.error-message-attempt {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4444;
  color: white;
  padding: 15px 30px;
  border-radius: 4px;
  animation: slideUp 0.3s ease-in-out;
  z-index: 1000;
}

@keyframes slideUp {
  from { transform: translate(-50%, 100%); }
  to { transform: translate(-50%, 0); }
}
