.location-card {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  padding: 16px;
  transition: transform 0.2s ease-in-out;
}

.api-credit {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.6em;
  color: #666;
  font-style: italic;
}

.carousel-container {
  width: 100%;
    margin: 10px 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .carousel .slide {
    background: none;
    height: 200px; /* Set a fixed height for all slides */
  }

  .carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel .control-arrow {
    background: rgba(0, 0, 0, 0.2);
    padding: 15px;
    transition: background 0.15s ease-in-out;
  }

  .carousel .control-arrow:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  .carousel .control-dots {
    bottom: 10px;
  }

  .carousel .dot {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .carousel .dot.selected {
    background: #007bff;
  }
.location-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.location-card h3 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  color: #333;
}

.location-card p {
  margin: 4px 0;
  font-size: 14px;
  color: #666;
}

.location-card img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.location-card a {
  display: inline-block;
  margin-top: 8px;
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

.location-card a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .location-card {
    padding: 10px; /* Reduce padding */
    margin-bottom: 10px; /* Reduce margin */
  }

  .location-card h3 {
    font-size: 16px; /* Reduce font size */
  }

  .location-card p {
    font-size: 12px; /* Reduce font size */
    margin: 2px 0; /* Reduce margin */
  }

  .location-card img {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .location-card a {
    font-size: 12px; /* Reduce font size */
  }
}
